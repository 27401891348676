import { EnvironmentConstants } from '@/constants/enviromentConstants';
import axios from 'axios';

export class EmailRepository{

    private baseApiUrl = EnvironmentConstants.HOST;

    public async sendMail(body: any): Promise<string> {
        return (await (axios.post(this.baseApiUrl+'mail',body))).data
    }
}

export const emailRepository = new EmailRepository();