
import { Component, Vue, Watch } from 'vue-property-decorator';
/* COMPONENTS */
import SvgIcon from '@/components/utilities/SvgIcon.vue';
import Header from '@/modules/landingPage/components/eu/layout/Header.vue';
import Footer from '@/modules/landingPage/components/eu/layout/Footer.vue';
/* INTERFACES */
/* STORE */
/* FUNCTIONS */
import {emailRepository} from '@/http/email/email.repository';
import { showToast } from '@/utils/global-functions';
import { WHATSAPP_CONSTANTS, WHATSAPP_PHONES, SOCIAL_MEDIA, EUROPE_PHONE } from '@/constants/criptoremesa.constants.ts'
@Component({
  components: {
      SvgIcon,
      Header,
      Footer,
  },
})
export default class Contacts extends Vue {
contacts= [
    {
        image: 'whatsapp.png',
        name: 'Whatsapp',
        value: EUROPE_PHONE.phone,
        link: `https://wa.me/${EUROPE_PHONE.nonFormattedPhone}`
    },
    {
        image: 'email.png',
        name: 'Correo electrónico',
        value: 'contacto@bithonor.es'
    },
]
socialMedia= SOCIAL_MEDIA
mailMessage ={
    fullName: '',
    email:'',
    country:'',
    phone:'',
    message:''
}
mailMessageLoader = false;
async sendMessageEmail(){
    try{
        if (this.mailMessage.fullName.length === 0 || this.mailMessage.email.length === 0) showToast('Debe completar los datos del formulario','error')
        // else if (!this.mailMessage.termsAndConditions) showToast('Debe aceptar los términos y condiciones','error')
        else {
            this.mailMessageLoader=true;
            let response = await emailRepository.sendMail(
                {
                    "subject": "Nuevo mensaje",
                    "title": "Has recibido un nuevo mensaje desde el landing de Bithonor",
                    "subtitle": "",
                    "firstParagraph": `Nombre: ${this.mailMessage.fullName}`,
                    "secondParagraph": `Correo: ${this.mailMessage.email}`,
                    "thirdParagraph": `Teléfono: ${this.mailMessage.phone}`,
                    "fourthParagraph": `País: ${this.mailMessage.country}`,
                    "fifthParagraph" : this.mailMessage.message,
                    "from": "no-reply@bithonor.com",
                    "to": "hola@bithonor.com"
                }
            )
            this.mailMessageLoader=false;
            if (response === "Email succesfully sent") {
                showToast('Su mensaje ha sido enviado','success');
                this.mailMessage={fullName:'',email:'', country: '' , phone: '', message: ''}
            }
            else showToast('Ha ocurrido un error al enviar su mensaje','error')
        }
    } catch(e) {
        this.mailMessageLoader=false;
        showToast('Ha ocurrido un error al enviar su mensaje','error')
    }
        
    }
}
